.wrapper {
  background-color: #fecede;
  border: 3px solid red;
  padding: 60px 20px;
  color: black;
  overflow-x: auto;
}

.heading {
  margin-bottom: 12px;
}

.errorMessage {
  font-size: 14px;
  text-wrap: wrap;
}

.warningWrapper {
  background-color: rgb(255, 255, 173);
  border: 3px solid rgb(192, 192, 25);
  padding: 60px 20px;
  color: black;
  overflow-x: auto;
  .anchor {
    border: 1px solid #ffcc00;
    background-color: #fff4c1;
    color: orange;
  }
}
.anchor {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 30px;
  font-family: Arial, sans-serif;
  border: 1px solid #ffcc00;
  background-color: #fff4c1;
  color: orange;
  font-weight: 900;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
